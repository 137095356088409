/* body {
  overflow: hidden;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.UrlCollapse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20ch;
}

.quill-bg-white{
  border: 0.5px solid #e5e9f8;
  background-color: #fff;
}

.normal-white-space{
  white-space: normal;
}

.w-200px{
  width: 200px;
}

.mx-100{
  max-width: 100px;
}

.w-30px{
  width: 30px;
}

.upload-box {
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  height: 38px;
  display: flex;
  flex-direction: row;
  padding-inline: 8px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.w-150px{
  width: 150px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 40ch;
}

.line-clamp p {
  margin-bottom: 0;
}

strong {
  font-weight: bold !important;
}

#noteModal .modal-body {
  height: 300px;
  overflow-y: auto;
}

.curser-pointer {
  cursor: pointer;
}

.color-gray{
  color:  #808080;
}

.fs-14{
  font-size: 14px;
}

.fw-500{
  font-weight: 500;
}

.fw-300{
  font-weight: 300;
}

.fs-13{
  font-size: 13px;
}

.no-border{
  border: 0px;
}

.active-card{
background-color: rgba(249, 178, 32,0.2);
border: 1px solid #F9B220;
}

.in-active-card{
background-color: #fafafa;
border: 1px solid #e7e7e7;
}

.in-active-card,.active-card{
  border-radius: 4px;
  width: 47%;
  cursor: pointer;
}

.remove-tier-btn{
  border:1px solid #BE3740;
  border-radius: 4px;
  font-size: 13px;
  padding-block: 3px;
  padding-inline: 8px;
  color: #BE3740;
  background-color: rgba(190, 55, 64, 0.1);
  font-weight: 300;
}

.remove-tier-btn:hover{
  background-color: transparent;
}

.custom-table {
    border-radius: 4px;
    overflow: auto;
}

.table-header {
    background-color: #F7F8F9;
    border-radius: 6px;
    color: #8E949D;
    font-size: 12px;
    font-weight: 500;
    padding: 11px 16px !important;
}

.table-row,.active-table-row {
    display: flex;
    /* border-bottom: 1px solid #ccc; */
}

.deal-name-text{
color: #312E81;
}

.radius-top-table{
border-top-left-radius:10px ;
border-top-right-radius:10px ;
}

.radius-bottom-table{
border-bottom-left-radius:10px ;
border-bottom-right-radius:10px ;
}

.active-table-row{
color: #F9B220;
background-color: rgba(249, 178, 32,0.1);
border: 0.5px solid #ffcd69;
}

.active-table-row:hover{
  background-color: rgba(249, 178, 32,0.2);
}

.inactive-table-row{
border: 0.5px solid #E5E7EB;
color: #6B7280;
}

.table-row,.active-table-row{
font-size: 14px;
}

.table-header-cell,
.table-cell {
    padding: 8px 16px;
    flex: 1;
    min-width: 230px
}

.gap-14{
  gap: 14px;
}

.stepper-line{
  border: 4px solid #F4F7FA;
  margin-left: 6px;
}

.w-400{
  width: 400px;
}

.fw-700{
  font-weight: 700;
}

.go-back-div{
  display: flex;
  align-items: center;
  gap:10px;
  cursor: pointer;
}

.curser-default {
  cursor: default;
}
.default-btn {
  background-color: #eaa827 !important;
  border: 0 !important;
}
.default-btn:hover {
  border: 0.5px solid rgb(68, 67, 67) !important;
  border-color: initial !important;
}

.document-table tr td {
  font-size: 16px !important;
}

.color-default {
  color: #eaa827 !important;
}

.color-red {
  color: red !important;
}

.color-white {
  color: white !important;
}

.react-pdf__Document,
.react-pdf__Page {
  min-height: auto !important;
  max-height: 1500px !important;
  margin-bottom: 24px;
}
.scrolled::-webkit-scrollbar {
  width: 0.5em;
}

.scrolled::-webkit-scrollbar-track {
  box-shadow: transparent;
}

.scrolled::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
}

.scrolled::-webkit-scrollbar-thumb {
  background-color: #506e8c;
  outline: 1px solid transparent;
  border-radius: 15px;
}

.active-candidate-pipeline {
  color: #eaa827 !important;
  border-bottom: 3px solid #eaa827 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* .editor-wrapped {
  position: relative;
}
.quill {
  border-top: 1px solid #e5e9f8;
  border-bottom: 0;
}
.ql-toolbar {
  position: absolute;
  bottom: 44px;
  width: 100%;
  transform: translateY(100%);
}
.quill-custom-btn {
  margin-top: -37px;
  position: absolute;
  z-index: 100;
  right: 0;
  margin-right: 6px;
} */

.submitTag {
  font-size: 12px;
  padding: 11px;
  border-radius: 40px;
}

#submittedEmail .input-group-text {
  border-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 16px !important;
}
.styleCss .input-group-text {
  border: 0;
}

.otherMails:hover {
  text-decoration: underline !important;
}

.forMailRemove {
  position: absolute;
  right: 10px;
  color: white;
  cursor: pointer;
  bottom: 7px;
}

.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.custom-file-upload {
  padding: 6px 12px;
  height: 10rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-file-upload input[type='file'] {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.titile-count-message-color {
  color: #0e79b6;
}

.titile-count-message {
  font-size: 12px;
  justify-content: space-between;
  display: flex;
}
.titile-count-message small {
  font-size: 100%;
}

#stepBodyQuill span {
  color: black !important;
  background-color: transparent !important;
}

.text-link {
  color: #33475b;
}
.text-link:hover {
  text-decoration: underline !important;
  color: #f9b220;
}

.skillsSectionDetailDrawer {
  max-height: 10em;
  overflow: hidden;
  overflow-y: auto;
}

.skillsSectionDetailDrawer .checkboxStyle {
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  position: inherit;
  margin-right: 10px;
}
